import {useDispatch as _useDispatch} from "react-redux";
import {configureStore} from "@reduxjs/toolkit"
import {setupListeners} from "@reduxjs/toolkit/query"
import {api} from "./api"
import accounting from "../accounting/store/accounting-slice"
import calculation from "../tourism/journey-calculation/store/calculation-slice"
import costs from "../costs/store/costs-slice"
import banking from "../banking-api/store/banking-slice";
import userSettings from "./store-slices/userSettingsSlice";

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        accounting,
        calculation,
        costs,
        banking,
        userSettings,
    },
    middleware: middleware => middleware().concat(api.middleware)
})

export const useDispatch = _useDispatch

export default store
setupListeners(store.dispatch)
