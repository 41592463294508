import { createSlice} from "@reduxjs/toolkit"

const initialState = {};

const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState,
  reducers: {
    setUserContentLocale: (state, action) => {
      state.userContentLocale = action.payload;
    },
    updateSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  setUserContentLocale,
  updateSettings,
} = userSettingsSlice.actions;



export default userSettingsSlice.reducer;


export const selectUserContentLocale = (state) => state?.userSettings?.userContentLocale;